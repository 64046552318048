<template>
  <div>
  <el-card>
     <div class="cards">
       <div class="card_left">
          <div class="top">
       <el-input
       v-model="companyInput"
       @input="searchCompany"
       placeholder="请输入关键词查找表情"></el-input>
       <el-button @click="createType" class="create_btn">
            <i class="el-icon-plus"></i>
         </el-button>
          </div>
          <ul class="list" v-loading="listLoading">
      <li @click="changeActive(item)"
      :class="{'active':leftObj.categoryCode===item.categoryCode}" :title="item.categoryName"
      v-for="item in typeList" :key="item.categoryCode">
        <div class="left">
          <img  :src="item.categoryLogo" alt="">
          <div class="textLeft">{{item.categoryName}}</div>

        </div>
        <div class="rightIcon">
          <i @click.stop="editCategory(item)" title="编辑" class="el-icon-edit"></i>
          <i @click.stop="deleteCategory(item)" title="删除" class="el-icon-delete"></i>
        </div>
          </li>
          </ul>
       </div>
      <div class="card_right"  >
          <div class="top">{{leftObj.categoryName || ''}}</div>
          <div class="two" style="display:flex;justify-content: space-between;">
              <div  class="item active" style="margin-left:20px" >表情信息</div>
              <div>
                  <el-button @click="addUser" type="primary">
                      新增表情
                  </el-button>
              </div>
          </div>
     <div class="tableBox" >
      <div class="topAdd">
      </div>
       <el-table
       v-loading="tableLoading"
         element-loading-text="拼命加载中"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(255, 255, 255, 0.9)"
  :data="tableData"
  border
  height="calc(100vh - 280px)"
  style="width: 100%">
  <el-table-column
   type="index"
    align="center"
    label="序号" >
  </el-table-column>
  <el-table-column
    prop="emoteName"
     align="center"
    label="表情名称">
  </el-table-column>
  <el-table-column
    prop="emoteLogo"
    align="center"
    label="图片">
    <template slot-scope="{row}">
      <el-image :src="row.emoteLogo" style="width:60px;height:60px"
      :preview-src-list="[row.emoteLogo]"></el-image>
    </template>
  </el-table-column>
      <el-table-column
    prop="categoryName"
    align="center"
    label="分类名称">
  </el-table-column>

  <el-table-column
    prop="createDt"
    align="center"
    label="创建日期">
  </el-table-column>
      <el-table-column
    align="center"
    label="操作">
    <template slot-scope="scope">
      <el-button style="padding:0 4px" @click="edit(scope.row)" type="text">
          编辑
      </el-button>
      <el-popconfirm
      @confirm="delte(scope.row.id)"
title="确定删除吗？"
>
      <el-button style="padding:0 4px;color:red" slot="reference"  type="text">
          删除
      </el-button>
</el-popconfirm>

    </template>
  </el-table-column>

</el-table>
 <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="queryInfo.currPage"
    :page-sizes="[100, 200, 300, 400]"
    :page-size="100"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total">
  </el-pagination>
     </div>

      </div>
     </div>
  </el-card>
  <el-dialog
:visible.sync="dialogVisible"
width="520px">
 <template slot="title">
  <span v-if="isAdd">新增表情信息</span>
  <span v-else>编辑表情信息</span>
</template>
<el-form label-width="90px">
 <el-form-item label="表情名称">
  <el-input placeholder="请输入表情名称" required v-model="emoteForm.emoteName"></el-input>
 </el-form-item>
 <el-form-item required label="表情logo">
  <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleAvatarSuccessEmote"
  :before-upload="beforeAvatarUpload">
  <img v-if="emoteForm.emoteLogo" :src="emoteForm.emoteLogo" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
 </el-form-item>
</el-form>
<span slot="footer" class="dialog-footer">
  <el-button @click="dialogVisible = false">取 消</el-button>
  <el-button type="primary" @click="dialogBtn">确 定</el-button>
</span>
</el-dialog>

<el-dialog
  title="表情分类"
  :visible.sync="dialogType"
  width="520px">
 <el-form label-width="90px">
  <el-form-item label="分类名称" required>
    <el-input v-model="typeForm.categoryName" placeholder="请输入分类名称"></el-input>
  </el-form-item>
  <el-form-item label="分类排序">
    <el-input-number controls-position="right" v-model="typeForm.seq" :min="1" size="mini"  ></el-input-number>
  </el-form-item>
  <el-form-item label="分类logo" required>
    <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="typeForm.categoryLogo" :src="typeForm.categoryLogo" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>

  </el-form-item>
 </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogType = false">取 消</el-button>
    <el-button type="primary" :loading="dialogTypeLoading" :disabled="dialogTypeLoading" @click="submitTypeForm">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
  export default {
      data(){
          return{
            emoteForm:{
              emoteName:"",
              id:"",
              emoteLogo:""
            },
            dialogTypeLoading:false,
            typeForm:{
              categoryName:"",
              categoryLogo:"",
              seq:1,
            },
            dialogType:false,
              isAdd:false,
          dialogVisible:false,
          leftObj:{
            categoryCode:"",
            categoryName:"",
            categoryLogo:"",
              id:"",
              seq:""
          },
              total:0,
              tableData:[],
              tableLoading:false,
              queryInfo:{
                  currPage:1,
                  pageSize:100,
                  condition:{
                    categoryCode:""
                  }
              },
              index1:true,
              isEdit:false,
              rightLoading:true,
              dialogBtnLoading:false,
              dialogLoading:false,
              annexFileUrlFileList:[],
              fileUrlFileList:[],
              businessLicenseImgFileList:[],
              dialogImageUrl:"",
              oldCompanyList:[],
              companyInput:'',
              listLoading:false,
              typeList:[],
              dialogForm:{
              }
          }
      },
      created(){
          this.getLeftList()
      },
      watch:{
        'leftObj.memberCode'(v){
          this.getTableList()
        }
      },
      methods:{
        async deleteCategory(obj){
          let config={
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
       try{
        let message=await  this.$confirm('此操作将永久删除该分类,是否继续?', '提示', config)
          if(message=='confirm'){
            let res=await this.$http.post('emoteCategory/delete',{id:obj.id})
            if(res.data.code==200){
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        this.getLeftList()
      }else{
        this.$message.error(res.data.message)
      }
          }
       }catch(error){
       // console.log(error)
       }
        },
     async   submitTypeForm(){
      this.dialogTypeLoading=true
      let res=await this.$http.post('emoteCategory/save',this.typeForm)
      this.dialogTypeLoading=false
      this.dialogType = false
      if(res.data.code==200){
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success'
        });
        this.getLeftList()
      }else{
        this.$message.error(res.data.message)
      }

        },
        handleAvatarSuccess(res, file) {
        this.typeForm.categoryLogo = res.data.url
      },
      handleAvatarSuccessEmote(res){
        this.emoteForm.emoteLogo = res.data.url
      },
      beforeAvatarUpload(file) {
        const isImg = file.type.slice(0,5) === 'image';
        if (!isImg) {
          this.$message.error('请上传图片格式文件');
        }
        return isImg
      },
        createType(){ //创建分类
      if(this.oldCompanyList.length>0){
      var maxSeqList=this.oldCompanyList.sort((a,b)=>{
          return b.seq-a.seq
        })
      }
      console.log(maxSeqList)
        this.typeForm={
              categoryName:"",
              categoryLogo:"",
              seq:(maxSeqList[0].seq+1) || 1
            }
        this.dialogType=true
        },
        editCategory(obj){
          let {id,categoryName,categoryLogo,seq}=obj
          this.typeForm.id=id
          this.typeForm.categoryName=categoryName
          this.typeForm.categoryLogo=categoryLogo
          this.typeForm.seq=seq
          this.dialogType=true
        },
        async  delte(id){
          let res=await this.$http.post('emote/delete',{id})
          if(res.data.code==200){
              this.getTableList()
               this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success'
      });
          }else{
              this.$message.error(res.data.message)
          }
          },
          edit(row){
            this.emoteForm.id=row.id
            this.emoteForm.categoryCode=row.categoryCode
            this.emoteForm.emoteLogo=row.emoteLogo
            this.emoteForm.emoteName=row.emoteName
          this.dialogVisible=true
          this.isAdd=false
          },
        async  dialogBtn(){
           let data=JSON.parse(JSON.stringify(this.emoteForm))
           data.categoryCode=this.leftObj.categoryCode
          if(this.isAdd){
              delete data.id
          }
       let res=await this.$http.post('emote/save',data)
          if(res.data.code==200){
       this.$notify({
        title: '提示',
        message: '保存成功',
        type: 'success'
      });
      this.dialogVisible=false
      this.getTableList()
   }else{
      this.$message.error(res.data.message)
   }
          },
          addUser(){
              this.isAdd=true
              this.emoteForm= {
              emoteName:"",
              id:"",
              emoteLogo:""
            }
              this.dialogVisible=true
          },
       async   getTableList(){
          // this.tableLoading=true
      let res=await this.$http.post('emote/list',this.queryInfo)
      this.tableData=res.data.data.data
      this.total=res.data.data.count
      this.tableLoading=false
          },
  handleSizeChange(newSize) {
    this.queryInfo.pageSize = newSize;
    this.getTableList();
  },
  // 监听 页码值 改变事件
  handleCurrentChange(newSize) {
    this.queryInfo.currPage = newSize;
    this.getTableList();
  },
          searchCompany(){
   var keyWord = this.companyInput.trim();
      var len = this.oldCompanyList.length;
      var arr = [];
      var reg = new RegExp(keyWord);
      for(var i=0;i<len;i++){
          if(this.oldCompanyList[i].categoryName.match(reg)){
              arr.push(this.oldCompanyList[i]);
          }
      }
     this.typeList=arr
          },
        async  getLeftList(){
          this.listLoading=true
      let res=await this.$http.post('emoteCategory/queryList ',
      {})
this.listLoading=false
if(this.leftObj.categoryCode=='' && Array.isArray(res.data.data) && res.data.data.length!=0){
  this.leftObj={...res.data.data[0]}
  this.queryInfo.condition.categoryCode=res.data.data[0].categoryCode
}
this.typeList=res.data.data
this.oldCompanyList=res.data.data
 this.getTableList()
          },
          changeActive(obj){
            this.leftObj={...obj}
              this.queryInfo.condition.categoryCode=obj.categoryCode
              this.getTableList()
          },
       forceUpdate(){
          this.$forceUpdate()
       }
      }
  }
</script>

<style lang="less" scoped>
/deep/ .el-card__body{
  padding-bottom: 0;
}
/deep/ .smallLabel{
  width: 30%;
}
/deep/ .smallLabels{
  width: 48%;
}
.cards{
  width: 100%;
  display: flex;
  flex-grow: 0;
  .card_left{
      width: 300px;
      flex-shrink: 0;
      .top{
          display: flex;
          /deep/ .el-input__inner{
              height:36px !important;
              line-height: 36px !important;
          }
          .el-button{
              height:36px;
              border-radius: 7px;
          }
          .create_btn{
              background: #02a7f0;
              margin-left: 12px;
              i{
                  font-size: 18px;
                  font-weight: 700;
                  color: #fff;
              }
          }
      }
      .list{
          margin-top: 16px;
          margin-bottom: 0;
          height: calc(100vh - 150px);
          overflow: auto;
          li{
           display: flex;
            justify-content: space-between;
            align-items: center;
              user-select: none;
              cursor: pointer;
              height: 44px;
              list-style: none;
              background-color: #f2f2f2;
              color: #333;
              font-size: 14px;
              line-height: 44px;
             padding-right: 16px;
              padding-left: 40px;
                      >div.left{
                              display: flex;
                        align-items: center;
                width: 204px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                img{
                  width:28px;
                  height:28px;
                  border-radius:50%
                }
                .textLeft{
                  margin-left: 6px;
                  width: calc(100% - 34px);
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  word-break: break-all;
                }
              }
              .rightIcon{
                display: flex;
                justify-content: space-between;
                width: 35px;
                i{
                display: none;
              }
              }

          }
          li.active{
              background-color: #B2E4FB;
              font-weight: 600;
          }
                      li:hover{
              background: darken(#f2f2f2, 15%);
            i{
              display: block;
            }
            i:hover{
              font-weight: 600;
            }
          }
        li.active:hover{
              background-color: #B2E4FB;
          }
      }
  }
  .card_right{
      margin-left: 16px;
      flex-grow: 0;
      width: 100%;
      .top{
          color: #333;
          font-size: 22px;

      }
      .two{
          width: 100%;
          margin-top: 12px;
          font-weight: 550;
          font-size: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid #D7D7D7;
          .item{
              position: relative;
              color: #aaa;
              width: fit-content;
              cursor: pointer;
              user-select: none;
          }
          .active{
              color: #02a7f0;
          }
          .active::after{
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              border-radius: 2px;
              left: 0;
              bottom: -12px;
              background: #02a7f0;
          }
      }
      .itemBox{
          margin-top: 40px;
          .items{
              padding: 20px;
              border: 1px solid rgba(215, 215, 215, 1);
              .topTitle{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  .left{
                      color: #333;
                      font-size: 15px;
                      height: fit-content;
                      position: relative;
                      padding-left: 4px;
                  }
                  .left::before{
                      content: '';
                      width: 5px;
                      height: 100%;
                      background: #02a7f0;
                      position: absolute;
                      left: -6px;
                      top: 0;
                  }
                  .right{
                      >.el-button{
                          background: #02a7f0;
                          color: #fff;
                          line-height: 28px;
                      }
                      img{
                          height: 15px;
                          width: 15px;
                          transform: translateY(2px);
                          margin-right: 4px;
                      }
                  }
              }
          }
      }
  }
}
/deep/ .el-form-item__label::after{
content: ':';
padding-left: 2px;
}
/deep/ .online{
  color: #7f7f7f;
}
/deep/ .online::before{
  content:'',

}
/deep/ .scrollBox{
  height: calc(100vh - 170px);
  overflow: auto;
  padding-left: 10px;
  padding-left: 10px;
 border: 1px solid #d7d7d7;
}
/deep/
  .el-dialog__body{
      padding-top: 10px;
          .scrollBox{
      height: 65vh;
  }


  .itemBox{
      border-bottom: 1px dashed #aaa;
      margin-top: 16px;
  }
  .topTitle{
      padding: 0 0 18px;
      color: #333;
      font-size: 17px;
      .left{
          position: relative;
        padding-left: 12px;
      }
      .left::before{
                      content: '';
                      width: 5px;
                      height: 100%;
                      background: #02a7f0;
                      position: absolute;
                      left: 0;
                      top: 0;
                  }
  }
}
/deep/.imgScroll{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  img{
      width:300px;
      display: block;
      margin-right: 30px;
      margin-bottom: 30px;
  }

}
/deep/ .yyzz{
  width: 100%;
  .el-form-item__content{
      width: calc(100% - 74px);
  }
}
/deep/ .el-upload-dragger{
  width: 178px;
  height: 178px;
}
/deep/ .h200{
  min-height: 200px;
}
/deep/ .itemBox{
.el-upload--picture-card i{
  line-height: 178px;
}
.el-upload--picture-card{
  width: 180px;
  height: 180px;
}
}
/deep/ .el-upload-list{
  >li{
      width: 178px;
      height: 178px;
  }
}
.tableBox{
  .topAdd{
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
  }
}
/deep/ .alive{
  position: relative;
  color: #67C23A;
}
/deep/ .alive::before{
  content: '';
  position: absolute;
  display: inline-block;
  width: 6px;
  // margin-right: 6px;
  left: -13px;
  top: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #67C23A;
}
.create_btn{
                background: #02a7f0;
                margin-left: 12px;
                i{
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                }
            }
            /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
 /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
 /deep/ .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>
